import React from "react";
import PropTypes from "prop-types";
import get from "lodash.get";
import { getClasses } from "@washingtonpost/front-end-utils";

const defaultSize = "large";

const sizeLookup = {
  large: "lg",
  medium: "md"
};

const Count = ({ count = 0, size = defaultSize }) => {
  count = Math.round(Number.parseInt(count, 10) || 0);
  if (Number.isNaN(count) || count < 1) return null;

  const sizeToUse = get(sizeLookup, size, get(sizeLookup, defaultSize));

  const className = getClasses(`dib font-${sizeToUse} font--headline`, {
    "b bb bc-offblack": sizeToUse === "lg"
  });

  return <div className={className}>{count}</div>;
};

Count.propTypes = {
  count: PropTypes.string,
  size: PropTypes.oneOf(["large", "medium"])
};

export default Count;
